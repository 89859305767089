'use strict';

// Template Editor
angular.module('risevision.template-editor.services', [
  'risevision.common.config',
  'risevision.common.header',
  'risevision.common.gapi',
  'risevision.editor.services',
  'risevision.schedules.services',
  'risevision.storage.services'
]);
